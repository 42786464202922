.NavItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    padding: 0px 5px;
}
.NavItem:hover{
    cursor: pointer;
    background: var(--detailsColor);
}

.active{
    background-color: var(--detailsColor);
}

.NavItem_icon{
    width: 30px;
    height: auto;
    margin: 20px;
}
.NavItem_text{
    font-size: 0;
    transition: 1.2s all;
    color: var(--menuTextColor);
}