.projectView{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 100%;
    max-width: 660px;
    max-height: 550px;
    background: var(--backgroundColor);
    transition: 1s all;
    border: 1px solid var(--detailsColor);
    border-radius: 20px;
    z-index: 999;
}
.projectView button{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
}
.projectView h2{
    margin: 10px;
}
.projectView img{
    width: 100%;
    height: 70%;
    object-fit: cover;
    object-position: top;
}
.projectView p{
    padding: 10px;
}

.projectView a{
    text-decoration: none;
    color: var(--detailsColor);
    font-size: 18px;
}