#Settings_Page{
    background-image: url('../../Resources/settings_bg.jpg');
    
}
#Settings_Page *{
    position: relative;
}

#Settings_Page div button{
    width: 120px;
    height: 50px;
    margin: 10px;
    cursor: pointer;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.languageButtons button:nth-child(odd){
    background-image: url('../../Resources/norflag.png');
}
.languageButtons button:nth-child(even){
    background-image: url('../../Resources/ukflag.png');
}