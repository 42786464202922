
#Home_Page{
    background-image: url('../../Resources/background.webp');
}
#Home_Page *{
    position: relative;
}

#Home_Page img{
    width: 150px;
    height: 150px;
    object-fit: cover;
    object-position: top;
    border-radius: 25px;
    /*
    animation: glow 1.7s infinite alternate-reverse;
    */
    box-shadow: 0px 0px 10px 4px var(--detailsColor);
}

#Home_Page h1{
    margin: 40px 0px;
    font-size: 2.6rem;
}

#Home_Page div a img{
    width: 40px;
    height: 40px;
    margin: 0 10px;
    box-shadow: none;
    animation: none;
}

@keyframes glow{
    100% {
        box-shadow: 0 0 3px #fff, 0 0 10px #fff, 0 0 20px #fff, 0 0 10px var(--detailsColor),
        0 0 20px var(--detailsColor), 0 0 30px var(--detailsColor);
    }
}
