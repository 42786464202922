#Projects_Page{
    background-image: url('../../Resources/projects_bg.webp');
    background-position: center;
}
#Projects_Page h1{
    position: relative;
}
.projects_container{
    position: relative;
    width: 90%;
    height: 100%;
    max-width: 655px;
    max-height: 500px;
    margin-top: 20px;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}