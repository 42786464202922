.knowledge_row{
    margin-bottom: 10px;
}

.knowledge_row img{
    width: 50px;
    height: 50px;
    border-radius: 50px;
    margin: 10px;
}

