.LanguageSelect{
    position: absolute;
    max-width: 550px;
    max-height: 400px;
    width: 90%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 999;
    background-color: var(--backgroundColor);
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--mainTextColor);
    border-radius: 25px;
}

.LanguageSelect div{
    margin: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.LanguageSelect div button{
    width: 120px;
    height: 50px;
    margin: 0 10px;
    cursor: pointer;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.LanguageSelect div button:nth-child(odd){
    background-image: url('../../Resources/norflag.png');
}
.LanguageSelect div button:nth-child(even){
    background-image: url('../../Resources/ukflag.png');
}