.Sidebar{
    position: absolute;
    width: 75px;
    height: 100%;
    min-height: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0;
    background-color: var(--menuColor);
    transition: 1.2s all;
    z-index: 5;
}

.Sidebar_top{
    font-size: .9rem;
    color: var(--menuTextColor);
    padding: 10px 25px;
}
.Sidebar_top h2{
    font-size: 0;
    transition: 1.2s all;
}
.Sidebar_top button{
    position: absolute;
    top: 105px;
    right: -17px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid var(--menuColor);
    cursor: pointer;
}

.Sidebar_top button img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    transform: rotate(90deg);
    transition: 1.2s all;
}

.Sidebar_middle{
    display: flex;
    flex-direction: column;
}

.Sidebar_bottom{
    color: var(--menuTextColor);
    font-size: .8rem;
}
