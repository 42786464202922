.App {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  min-height: 550px;
}

.App_container{
  position: relative;
  background: var(--backgroundColor);
  width: 100%;
  height: 100%;
}

.Page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: var(--mainTextColor);
    background-size: cover;
}
.Page::before{
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: var(--backgroundImgColor);
}

@media screen and (max-width: 770px) {
  .App_container{
    padding-left: 75px;
  }
}