.ProjectElement{
    width: 300px;
    height: 200px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 1s all;
}

.ProjectElement img{
    height: calc( 100% - 50px );
    object-fit: cover;
    object-position: top;
}

.ProjectElement h2{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin: 0;
    padding: 0;
    background-color: var(--backgroundColor);
}
